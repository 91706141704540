<template>
  <base-tooltip-button @click="onClick" :small="small" :is-icon="iconOnly">
    <template v-slot:icon-name>
      {{ iconChevronDown }}
    </template>
    <template v-if="tooltip" v-slot:toolTip> {{ tooltip }} </template>
  </base-tooltip-button>
</template>

<script>
import { iconChevronDown } from "@/design/icon/iconConst";
import { eventNames } from "@/model/common/events/eventConst";

export default {
  name: "ChevronDownButton",
  components: {
    BaseTooltipButton: () =>
      import("@/components/shared/base/BaseTooltipButton")
  },
  data() {
    return {
      iconChevronDown: iconChevronDown
    };
  },
  props: {
    tooltip: {
      type: String,
      default: undefined
    }
  },
  computed: {
    small() {
      return true;
    },
    iconOnly() {
      return true;
    }
  },
  methods: {
    onClick(e) {
      this.$emit(eventNames.click, e);
    }
  }
};
</script>
